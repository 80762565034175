import "./style.css"
import * as THREE from "three"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader"

// Canvas
const canvas = document.querySelector("canvas.webgl")

// Scene
const scene = new THREE.Scene()
scene.translateX(1.2)
scene.translateY(-1)

/**
 * Models
 */
const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath("/draco/")

const gltfLoader = new GLTFLoader()
gltfLoader.setDRACOLoader(dracoLoader)
let model
gltfLoader.load("./models/ruby/standup-pouch.glb", (gltf) => {
  gltf.scene.scale.set(10, 10, 10)
  //gltf.scene.position.set(1,0,0)
  model = gltf.scene
  scene.add(gltf.scene)
})

/**
 * Floor
 */
const floor = new THREE.Mesh(
  new THREE.PlaneGeometry(10, 10),
  new THREE.MeshStandardMaterial({
    color: "#ccc",
    roughness: 0.95,
  })
)
floor.position.x = 1
floor.rotation.x = -Math.PI * 0.5
floor.rotation.z = 1
scene.add(floor)

/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight(0xffffff, 0.25)
scene.add(ambientLight)

const directionalLight = new THREE.PointLight(0xffffff, 0.5)
directionalLight.position.set(0, 2.5, 4)
scene.add(directionalLight)

const directionalLightB = new THREE.PointLight(0xffffff, 0.5)
directionalLightB.position.set(0, 3, -3)
scene.add(directionalLightB)

/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
}
let x = window.matchMedia("(max-width: 600px)")
window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth
  sizes.height = window.innerHeight

  // Update camera
  camera.aspect = sizes.width / sizes.height

  if (x.matches) {
    console.log("no upm()")
  } else {
    camera.updateProjectionMatrix()
  }

  // Update renderer
  renderer.setSize(sizes.width, sizes.height)
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
  renderer.outputEncoding = THREE.sRGBEncoding
})

/**
 * Camera
 */
// Base camera

const camera = new THREE.PerspectiveCamera(45, 1, 0.1, 100)
if (x.matches) {
  // If media query matches
  camera.position.set(1, 1, 7) /** 7 this is changing */
} else {
  camera.position.set(1, 1, 3)
}

var fullWidth = window.innerWidth
var fullHeight = window.innerHeight

if (x.matches) {
  // If media query matches
  var xPixels = 30
  var yPixels = -20
  var fullHeight = 400
} else {
  var xPixels = -220
  var yPixels = -15
}

camera.setViewOffset(
  fullWidth,
  fullHeight,
  xPixels,
  yPixels,
  fullWidth,
  fullHeight
)
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.target.set(1, 0, 0)
controls.enabled = true
controls.enableZoom = false

controls.enableDamping = true
controls.dampingFactor = 0.01
controls.screenSpacePanning = false

controls.minPolarAngle = 1.15
controls.maxPolarAngle = 1.55
controls.rotateSpeed = 3.5

controls.minDistance = 3.5
controls.maxDistance = 3.8

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  alpha: true,
  antialias: true,
})

if (x.matches) {
  // If media query matches
  renderer.setSize(375, 500)
} else {
  renderer.setSize(sizes.width, sizes.height)
}

renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.outputEncoding = THREE.sRGBEncoding

/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0

const tick = () => {
  const elapsedTime = clock.getElapsedTime()
  const deltaTime = elapsedTime - previousTime
  previousTime = elapsedTime

  // Update controls
  controls.update()

  // Render
  renderer.render(scene, camera)

  // Call tick again on the next frame
  window.requestAnimationFrame(tick)
}

tick()
let mouse = { x: 0, y: 0 }
let cameraMoves = { x: 0, y: 0, z: 0, move: true, speed: 0.015 }

function mouseMove(e) {
  camera.position.x += Math.max(
    Math.min((e.clientX - mouse.x) * 2.1, cameraMoves.speed),
    -cameraMoves.speed
  )
  camera.position.y += Math.max(
    Math.min((mouse.y - e.clientY) * 0.001, cameraMoves.speed),
    -cameraMoves.speed
  )
  mouse.x = e.clientX
  mouse.y = e.clientY
}
window.addEventListener("mousemove", mouseMove)
